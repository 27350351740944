// import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import heart from "../../assets/images/shapes/Green-ball.png";
import { Link } from "@gatsbyjs/reach-router";

const AakdProgram = ({ showbutton, top }) => {
  return (
    <section className={`${top && "pt-120"} pb-120`}>
      <Container>
        <Row className="pb-40">
          <Col lg={10}>
            <div className="donate-options__content">
              <div className="block-title">
                <p>
                  <img src={heart} width="15" alt="" />
                  Transforming Lives One Shot at a Time
                </p>
                <h3>Arthur Ashe Kids Day (AAKD)</h3>
              </div>
              <p>
                Made You Love Tennis Foundation and AYSI (Athletes Youth Sports
                Institute) have joined forces as ambassadors of the late great
                Coach Wink's National Junior Tennis Learning (NJTL) program to
                continue his legacy of connecting hard work and dedication to
                endless possibilities for enriching one's life. As a reward for
                academic and athletic commitment throughout the school year and
                tennis season, the youth are annually taken to 2024 Arthur Ashe
                Kids' Day (AAKD) at the US Open in New York.
              </p>
            </div>
          </Col>
        </Row>
        {showbutton && (
          <Link className="thm-btn dynamic-radius" to="/events/aakd-us-open-nyc">
            Learn More
          </Link>
        )}
      </Container>
    </section>
  );
};

export default AakdProgram;
